@import "abstracts/variables";

@mixin typography-base-important() {
  font-size: $base-font-size;
  font-weight: $renovero-font-bold;
  line-height: $font-line-height;
  letter-spacing: 0;
}

@mixin typography-heading-small-important() {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin typography-heading-big-important() {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;

  @include from-size-md() {
    font-size: 30px;
    line-height: 36px;
  }
}

@mixin typography-heading-medium-big() {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
}

@mixin typography-heading-big() {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
}

@mixin typography-important {
  font-weight: 600;
}

@mixin typography-text-small() {
  font-size: $font-size-14;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
}

@mixin typography-text-large() {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
}
@mixin typography-text-mobile-large() {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

@mixin important() {
  font-weight: 600;
}

@mixin typography-text-base() {
  font-family: "Encode Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

@mixin typography-text-tiny() {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}
@mixin typography-text-tiniest() {
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
}

@mixin typography-text-tiny-important() {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
}
@mixin typography-text-small-bold() {
  font-size: $font-size-14;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
}

@mixin heading-emphasis() {
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
}
