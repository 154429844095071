@import "abstracts/variables";

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-mdc-tab-labels {
    gap: $spacing-24;
  }

  .mat-mdc-tab {
    flex-grow: 0 !important;
    padding: 0;

    &:not(.mat-mdc-tab-disabled) {
      font-size: $base-font-size;
      font-weight: $renovero-font-bold;
      letter-spacing: normal;
      text-transform: uppercase;

      &:hover .mdc-tab__ripple::before {
        background-color: transparent;
      }

      &.mdc-tab--active .mdc-tab__text-label {
        color: $renovero-blue;
      }

      .mdc-tab__text-label {
        color: rgba($renovero-blue, 0.7);
      }
    }
  }

  // This is mainly needed so that the shadow does not disappear
  .mat-mdc-tab-body-wrapper,
  .mat-mdc-tab-body.mat-mdc-tab-body-active,
  .mat-mdc-tab-body-content {
    overflow: visible !important;
  }
}
