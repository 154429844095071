@import "abstracts/variables";

@each $spacing in $spacings {
  .m-#{strip-units($spacing)} {
    margin: #{$spacing};
  }

  .mt-#{strip-units($spacing)} {
    margin-top: #{$spacing};
  }

  .mr-#{strip-units($spacing)} {
    margin-right: #{$spacing};
  }

  .mb-#{strip-units($spacing)} {
    margin-bottom: #{$spacing};
  }

  .ml-#{strip-units($spacing)} {
    margin-left: #{$spacing};
  }

  .mx-#{strip-units($spacing)} {
    margin-left: #{$spacing};
    margin-right: #{$spacing};
  }

  .my-#{strip-units($spacing)} {
    margin-top: #{$spacing};
    margin-bottom: #{$spacing};
  }
}
