@import "abstracts/functions";
@import "abstracts/containers_mixins";
@import "abstracts/mixins";
@import "abstracts/variables";

.section-normal {
  @include section-normal();
}

.section-narrow {
  @include section-narrow();
}

.section-background-blue {
  background-color: $renovero-blue;
}

.section-background-grey {
  background-color: $renovero-light-gray;
}

.section-background-transparent {
  background-color: transparent;
}

.section-background-light-blue {
  background-color: $renovero-light-blue;
}

.section-background-light-blue-extra {
  background-color: rgba($renovero-light-blue, 0.5);
}

.section-background-yellow {
  background-color: $renovero-yellow;
}

.vertical-padding-normal {
  padding-top: $spacing-48;
  padding-bottom: $spacing-64;

  @include from-size-md() {
    padding-top: $spacing-64;
    padding-bottom: $spacing-96;
  }
}

.vertical-padding-normal-no-horizontal {
  padding-top: $spacing-48;
  padding-bottom: $spacing-64;

  @include from-size-md() {
    padding-top: $spacing-64;
    padding-bottom: $spacing-96;
  }
}

.vertical-padding-normal-64x64 {
  padding-top: $spacing-48;
  padding-bottom: $spacing-64;

  @include from-size-md() {
    padding-top: $spacing-64;
  }
}

.vertical-padding-normal-32x32 {
  padding-top: $spacing-48;
  padding-bottom: $spacing-32;

  @include from-size-md() {
    padding-top: $spacing-32;
  }
}

.form-group {
  padding: $spacing-16;
  border: 3px solid $renovero-light-gray;
  border-radius: $renovero-border-radius;
}
