@import "abstracts/variables";
@import "abstracts/mixins";
@import "v2/mixins";

.vertical-padding-v2-normal {
  padding-top: $spacing-v2-50;
  padding-bottom: $spacing-v2-50;

  @include from-size-md() {
    padding-top: $spacing-v2-120;
    padding-bottom: $spacing-v2-120;
  }
}

.vertical-padding-v2-normal-40x40 {
  padding-top: $spacing-v2-30;
  padding-bottom: $spacing-v2-30;

  @include from-size-md() {
    padding-top: $spacing-v2-40;
    padding-bottom: $spacing-v2-40;
  }
}

.vertical-padding-v2-normal-40x80 {
  padding-top: $spacing-v2-30;
  padding-bottom: $spacing-v2-60;

  @include from-size-md() {
    padding-top: $spacing-v2-40;
    padding-bottom: $spacing-v2-80;
  }
}

.vertical-padding-v2-normal-80x40 {
  padding-top: $spacing-v2-60;
  padding-bottom: $spacing-v2-30;

  @include from-size-md() {
    padding-top: $spacing-v2-80;
    padding-bottom: $spacing-v2-40;
  }
}

.vertical-padding-v2-normal-80x80 {
  padding-top: $spacing-v2-50;
  padding-bottom: $spacing-v2-50;

  @include from-size-md() {
    padding-top: $spacing-v2-80;
    padding-bottom: $spacing-v2-80;
  }
}

.vertical-padding-v2-normal-60x60 {
  padding-top: $spacing-v2-25;
  padding-bottom: $spacing-v2-25;

  @include from-size-md() {
    padding-top: $spacing-v2-60;
    padding-bottom: $spacing-v2-60;
  }
}

.vertical-padding-v2-normal-60x120 {
  padding-top: $spacing-v2-25;
  padding-bottom: $spacing-v2-50;

  @include from-size-md() {
    padding-top: $spacing-v2-60;
    padding-bottom: $spacing-v2-120;
  }
}

.vertical-padding-v2-normal-120x60 {
  padding-top: $spacing-v2-50;
  padding-bottom: $spacing-v2-25;

  @include from-size-md() {
    padding-top: $spacing-v2-120;
    padding-bottom: $spacing-v2-60;
  }
}

.vertical-padding-v2-normal-120x120 {
  padding-top: $spacing-v2-50;
  padding-bottom: $spacing-v2-50;

  @include from-size-md() {
    padding-top: $spacing-v2-120;
    padding-bottom: $spacing-v2-120;
  }
}

.vertical-padding-v2-normal-0x120 {
  padding-bottom: $spacing-v2-50;

  @include from-size-md() {
    padding-bottom: $spacing-v2-120;
  }
}

.vertical-padding-v2-normal-120x0 {
  padding-top: $spacing-v2-50;

  @include from-size-md() {
    padding-top: $spacing-v2-120;
  }
}

.section-v2-normal {
  @include section-v2-normal();
}

.view-desktop {
  @include view-desktop();
}

.view-mobile {
  @include view-mobile();
}
