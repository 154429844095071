@import "abstracts/typography_mixins";
@import "abstracts/variables";
@import "abstracts/mixins";

.text-base-important {
  @include typography-base-important();
}

.text-with-newlines {
  white-space: pre-wrap;
}

.font-weight-light {
  font-weight: $renovero-font-light;
}

.font-weight-regular {
  font-weight: $renovero-font-regular;
}

.font-weight-bold {
  font-weight: $renovero-font-bold;
}

.typography-heading-small-important {
  @include typography-heading-small-important();
}

.typography-heading-big {
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;

  @include from-size-md() {
    font-size: 30px;
    line-height: 40px;
  }
}

.typography-heading-big-important {
  @include typography-heading-big-important();
}

.typography-text-small {
  @include typography-text-small();
}
.typography-text-small-important {
  @include typography-text-small();
  @include typography-important();
}

.typography-text-mobile-large {
  @include typography-text-mobile-large();
  @include from-size-md() {
    @include typography-text-large();
  }
}
.typography-text-large {
  @include typography-text-large();
}
.typography-text-large-important {
  @include typography-text-large();
  @include typography-important();
}

.typography-text-large {
  @include typography-text-large();
}
.typography-text-large-important {
  @include typography-text-large();
  @include important();
}
.typography-text-tiny {
  @include typography-text-tiny();
}
.typography-text-tiny-important {
  @include typography-text-tiny-important();
}

.typography-mob-tiniest-tab-text-tiny-important {
  @include typography-text-tiniest();
  @include from-size-md() {
    @include typography-text-tiny-important();
  }
}

.typography-text-base {
  @include typography-text-base();
}
.typography-text-base-important {
  @include typography-text-base();
  @include important();
}

.typography-text-base-to-heading-small-important {
  @include typography-text-base();
  @include important();
  @include from-size-md() {
    @include typography-heading-small-important();
  }
}

.typography-text-small-bold {
  @include typography-text-small-bold();
}

.typography-encode-sans-heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;

  @include from-size-md() {
    line-height: 30px;
  }
}

.typography-encode-sans-text-no-scale {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.typography-encode-sans-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  @include from-size-md() {
    font-size: 18px;
    line-height: 30px;
  }
}

.heading-emphasis {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;

  @include from-size-md() {
    font-size: 21px;
    line-height: 28px;
  }
}

.typography-heading-emphasis-important {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.typography-text-small-to-text-base {
  @include typography-text-small();

  @include from-size-md() {
    @include typography-text-base();
  }
}

.typography-text-small-to-heading-emphasis {
  @include typography-text-small();
  @include from-size-md() {
    @include heading-emphasis();
  }
}

.typography-heading-big-no-scale {
  @include typography-heading-big();
}

.heading-emphasis-no-scale {
  @include heading-emphasis();
}

.typography-text-small-base {
  @include typography-text-small();
  @include from-size-md() {
    @include typography-text-base();
  }
}
.typography-text-small-base-important {
  @include typography-text-small-bold();
  @include from-size-md() {
    @include typography-text-base();
    @include important();
  }
}

.typography-text-base-to-heading-emphasis-important {
  @include typography-text-base();
  @include important();
  @include from-size-md() {
    @include heading-emphasis();
    @include important();
  }
}

.typography-text-tiny-to-small {
  @include typography-text-tiny();
  @include from-size-md() {
    @include typography-text-small();
  }
}

.typography-link {
  background: none;
  border: 0;
  border-bottom: 2px solid $renovero-light-blue;
  color: $renovero-blue;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  transition: border-bottom-color 0.5s;

  &:hover {
    border-bottom-color: $renovero-blue;
  }
}

.typography-text-large-to-heading-emphasis-important {
  @include typography-text-large();
  @include important();
  @include from-size-md() {
    @include heading-emphasis();
    @include important();
  }
}

.heading-emphasis-to-medium-big-important {
  @include heading-emphasis();
  @include important();
  @include from-size-md() {
    @include typography-heading-medium-big();
    @include important();
  }
}
