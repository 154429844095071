@import "abstracts/variables";

.radio-button.filter {
  .mdc-radio {
    padding-left: 0;
  }
}

.mat-mdc-radio-button {
  .mdc-form-field {
    color: $renovero-blue !important;
  }
}
