@import "abstracts/variables";
@import "abstracts/mixins";

gallery-item {
  .caption {
    position: absolute;
    bottom: 0;
    background: $black-with-transparency;
    width: 100%;
    text-align: center;
    padding: $spacing-8;
    margin: 0;
    max-width: 100%;
  }

  .gallery-image-download-button {
    cursor: pointer;
    left: $spacing-16;
    opacity: 0.6;
    top: $spacing-16;
    transition: 0.2s linear;
    position: absolute;

    &:hover {
      opacity: 1;
    }
  }
}
