@import "abstracts/variables";
@import "abstracts/mixins";
@import "variables";

@mixin typography-h1() {
  margin: 0;
  font-weight: $renovero-font-bold;
  font-size: $font-v2-size-36;
  line-height: 42px;

  @include from-size-md() {
    font-size: $font-v2-size-64;
    line-height: 68px;
  }

  @content;
}

@mixin typography-h2() {
  margin: 0;
  font-weight: $renovero-font-bold;
  font-size: $font-v2-size-28;
  line-height: 36px;

  @include from-size-md() {
    font-size: $font-v2-size-48;
    line-height: 56px;
  }

  @content;
}

@mixin typography-h3() {
  margin: 0;
  font-weight: $renovero-font-bold;
  font-size: $font-v2-size-24;
  line-height: 32px;

  @include from-size-md() {
    font-size: $font-v2-size-32;
    line-height: 40px;
  }

  @content;
}

@mixin typography-h4() {
  margin: 0;
  font-weight: $renovero-font-bold;
  font-size: $font-v2-size-22;
  line-height: 28px;

  @include from-size-md() {
    font-size: $font-v2-size-24;
    line-height: 32px;
  }

  @content;
}

@mixin typography-h5() {
  margin: 0;
  font-weight: $renovero-font-bold;
  font-size: $font-v2-size-20;
  line-height: 24px;

  @include from-size-md() {
    font-size: $font-v2-size-20;
    line-height: 28px;
  }

  @content;
}

@mixin typography-text-1($margin: 0, $font-weight: $renovero-font-regular) {
  @if ($margin != null) {
    margin: $margin;
  }
  font-size: $font-v2-size-16;
  line-height: 26px;
  font-weight: $font-weight;

  @content;
}

@mixin typography-text-2() {
  margin: 0;
  font-weight: $renovero-font-regular;
  font-size: $font-v2-size-15;

  @content;
}

@mixin typography-text-2--bold() {
  @include typography-text-2();
  font-weight: $renovero-font-extra-bold;
  line-height: 24px;

  @content;
}

@mixin typography-text-2--bold-uppercase() {
  @include typography-text-2--bold();
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @content;
}

@mixin typography-text-3() {
  margin: 0;
  font-weight: $renovero-font-regular;
  font-size: $font-v2-size-14;
  line-height: 24px;

  @include from-size-md() {
    line-height: 22px;
  }

  @content;
}

@mixin typography-text-3--medium() {
  @include typography-text-3();
  font-weight: $renovero-font-medium;

  @content;
}

@mixin typography-text-3--semibold() {
  @include typography-text-3();
  font-weight: $renovero-font-bold;

  @content;
}

@mixin typography-text-4() {
  margin: 0;
  font-weight: $renovero-font-regular;
  font-size: $font-v2-size-12;
  line-height: 16px;

  @content;
}

@mixin typography-text-4--medium() {
  @include typography-text-4();
  font-weight: $renovero-font-medium;

  @content;
}

@mixin typography-text-4--semibold-uppercase() {
  @include typography-text-4();
  font-weight: $renovero-font-bold;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 0.5px;

  @content;
}

@mixin typography-text-4--extrabold-uppercase() {
  @include typography-text-4();
  font-weight: $renovero-font-super-bold;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 0.5px;

  @content;
}

@mixin typography-text-5() {
  margin: 0;
  font-weight: $renovero-font-regular;
  font-size: $font-v2-size-14;
  line-height: 22px;

  @content;
}

@mixin typography-text-5--medium() {
  @include typography-text-5();
  font-weight: $renovero-font-medium;

  @content;
}

@mixin typography-text-5--bold() {
  @include typography-text-5();
  font-weight: $renovero-font-bold;

  @content;
}

@mixin section-v2-normal() {
  padding: 0 $spacing-v2-16;
  max-width: $container-size-v2;
  margin: 0 auto;

  @include from-size-md() {
    padding: 0 $spacing-v2-24;
  }
}

@mixin view-desktop() {
  display: none;

  @include from-size-md() {
    display: block;
  }
}

@mixin view-mobile() {
  display: block;

  @include from-size-md() {
    display: none;
  }
}

@mixin action-line($backgroundColor) {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: $backgroundColor;
  @content;
}

@mixin action-border($beforeBackgroundColor: $renovero-blue, $showBefore: true) {
  position: relative;
  padding-bottom: $spacing-v2-3;

  @if $showBefore {
    &::before {
      @include action-line($beforeBackgroundColor) {
        width: 100%;
      }
    }
  }

  &::after {
    @include action-line($renovero-yellow) {
      width: 0;
      background-color: $renovero-yellow;
      transition: width 0.2s ease-out;
    }
  }
}

@mixin action-border-hover() {
  cursor: pointer;

  &:hover {
    .action-border {
      &::after {
        width: 100%;
      }
    }
  }
}

@mixin from-size-sm-v2() {
  @include from-size($breakpoint-v2-sm) {
    @content;
  }
}
@mixin from-size-md-v2() {
  @include from-size($breakpoint-v2-md) {
    @content;
  }
}
@mixin from-size-lg-v2() {
  @include from-size($breakpoint-v2-lg) {
    @content;
  }
}
@mixin from-size-xl-v2() {
  @include from-size($breakpoint-v2-xl) {
    @content;
  }
}
@mixin from-size-xxl-v2() {
  @include from-size($breakpoint-v2-xxl) {
    @content;
  }
}

@mixin size-from-to($start, $end) {
  @media (min-width: $start) and (max-width: $end) {
    @content;
  }
}

@mixin card-v2() {
  --card-border-color: rgb(220, 223, 230);

  border: 1px solid var(--card-border-color);
  border-radius: $spacing-4;
  display: flex;
  flex-direction: column;
  margin: $spacing-16 0;
  padding: $spacing-24 $spacing-16;
  position: relative;

  @content;

  .header {
    display: flex;

    .title {
      @include typography-h4();
    }

    .action {
      display: flex;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
    }
  }
}
