/* classic renovero colors */
$renovero-yellow: #f9b415;
$renovero-light-yellow: #ffeec6;
$renovero-blue: #203768;
$renovero-light-blue: #dbdfe7;
$renovero-gray: #a9a9a9;
$renovero-light-gray: #f3f2f2;
$renovero-dark-gray: #4f4f4f;
$renovero-black: #1f1f20;
$renovero-red: #e20613;
$renovero-light-red: #ff8080;
$renovero-white: #ffffff;
$renovero-red-danger: #e53b3b;
$renovero-modal-overlay: #060f22;

/* mojo colors */
$mojo-green: #27ae60;
$mojo-light-green: lighten($mojo-green, 20%);

/* mojo style breakpoints */
$mojo-breakpoint-xs: 576px;
$mojo-breakpoint-md: 768px;
$mojo-breakpoint-md-plus: 960px;
$mojo-breakpoint-lg: 1440px;

// new
$renovero-border-radius: 2px;
$renovero-border-color: #e8e8e8;
$renovero-border-normal: $renovero-border-radius solid $renovero-border-color;
$renovero-border-yellow: $renovero-border-radius solid $renovero-yellow;

$spacing-196: 196px;
$spacing-96: 96px;
$spacing-80: 80px;
$spacing-72: 72px;
$spacing-64: 64px;
$spacing-56: 56px;
$spacing-48: 48px;
$spacing-40: 40px;
$spacing-32: 32px;
$spacing-24: 24px;
$spacing-16: 16px;
$spacing-12: 12px;
$spacing-10: 10px;
$spacing-8: 8px;
$spacing-7: 7px;
$spacing-6: 6px;
$spacing-4: 4px;
$spacing-2: 2px;
$spacing-0: 0px;

$spacings: $spacing-0, $spacing-2, $spacing-4, $spacing-8, $spacing-12, $spacing-16, $spacing-24, $spacing-32,
  $spacing-40, $spacing-48, $spacing-56, $spacing-64, $spacing-72, $spacing-96, $spacing-196;

$box-shadow-inset-light: inset 0 0 12px rgba(0, 0, 0, 0.05);
$box-shadow-normal: 0px 0px 48px 8px rgba(0, 0, 0, 0.05);
$box-shadow-medium: 0px 0px 21px 5px rgba(0, 0, 0, 0.15);
$box-shadow-light: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
$box-shadow-strong: 0 2px 14px 0 rgba(0, 0, 0, 0.5);

// renovero forms
$form-width: 516px;
$error-color: $renovero-red;
$error-background-color: $renovero-light-red;
$renovero-placeholder-color: #dddddd;
$renovero-control-padding: 16px;

$default-spacer-small: 5px;
$default-spacer-half: 10px;
$default-spacer: 20px;
$default-spacer-large: 40px;
$renovero-negative-margin: -20px;
$renovero-component-margin: $default-spacer $renovero-negative-margin $default-spacer $renovero-negative-margin;

$renovero-font-light: 200;
$renovero-font-heading-emphasis: 300;
$renovero-font-regular: 400;
$renovero-font-medium: 500;
$renovero-font-bold: 600;
$renovero-font-extra-bold: 700;
$renovero-font-super-bold: 800;

$base-font-size: 16px;
$font-size-14: 14px;
$font-size-small: 12px;
$font-size-medium: 21px;
$font-line-height: 24px;
$font-line-height-small: 18px;
$font-color-muted: #7a7a7a;

$content-max-width: 1024px;

$black-with-transparency: rgba(0, 0, 0, 0.7);

$search-bar-width: 650px;
$google-map-height: 500px; // standard google map height

// new layout v2
$renovero-v2-gray: #818ba1;
$renovero-v2-main-light-gray: #dbdfe7;
$renovero-v2-secondary-light-gray: #dbdfe780;
$renovero-v2-box-shadow-black: #0000001a;
$renovero-v2-full-black: #000000;
$renovero-v2-secondary-light-yellow: #ffc84a;
$renovero-v2-blue-dark: #162850;

$box-shadow-v2-black: 0px 10px 20px 8px $renovero-v2-box-shadow-black;

$spacing-v2-148: 148px;
$spacing-v2-140: 140px;
$spacing-v2-120: 120px;
$spacing-v2-104: 104px;
$spacing-v2-96: 96px;
$spacing-v2-82: 82px;
$spacing-v2-80: 80px;
$spacing-v2-76: 76px;
$spacing-v2-74: 74px;
$spacing-v2-70: 70px;
$spacing-v2-62: 62px;
$spacing-v2-60: 60px;
$spacing-v2-52: 52px;
$spacing-v2-50: 50px;
$spacing-v2-48: 48px;
$spacing-v2-42: 42px;
$spacing-v2-40: 40px;
$spacing-v2-30: 30px;
$spacing-v2-36: 36px;
$spacing-v2-32: 32px;
$spacing-v2-30: 30px;
$spacing-v2-28: 28px;
$spacing-v2-26: 26px;
$spacing-v2-25: 25px;
$spacing-v2-24: 24px;
$spacing-v2-22: 22px;
$spacing-v2-20: 20px;
$spacing-v2-18: 18px;
$spacing-v2-16: 16px;
$spacing-v2-14: 14px;
$spacing-v2-12: 12px;
$spacing-v2-10: 10px;
$spacing-v2-8: 8px;
$spacing-v2-7: 7px;
$spacing-v2-6: 6px;
$spacing-v2-4: 4px;
$spacing-v2-3: 3px;
$spacing-v2-2: 2px;
$spacing-v2-1: 1px;

$size-v2-32: 32px;
$size-v2-20: 20px;
$size-v2-14: 14px;

$max-size-v2-680: 680px;
$max-size-v2-972: 972px;

$renovero-v2-double-border-radius: 4px;

$renovero-v2-font-extra-bold: 700;

$font-v2-size-64: 64px;
$font-v2-size-48: 48px;
$font-v2-size-36: 36px;
$font-v2-size-32: 32px;
$font-v2-size-28: 28px;
$font-v2-size-24: 24px;
$font-v2-size-22: 22px;
$font-v2-size-20: 20px;
$font-v2-size-18: 18px;
$font-v2-size-16: 16px;
$font-v2-size-15: 15px;
$font-v2-size-14: 14px;
$font-v2-size-12: 12px;

$container-size-v2: 1208px;

$column-size-v2-md: 420px;
$column-size-v2-lg: 624px;

$footer-social-icon-size-desktop: 36px;
$footer-social-icon-size-mobile: 48px;
