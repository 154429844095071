@import "abstracts/variables";

@mixin from-size-md {
  @include from-size($mojo-breakpoint-md) {
    @content;
  }
}

@mixin from-size-md-plus {
  @include from-size($mojo-breakpoint-md-plus) {
    @content;
  }
}

@mixin from-size-lg {
  @include from-size($mojo-breakpoint-lg) {
    @content;
  }
}

@mixin from-size($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: rgba($color, $opacity);
}

@mixin renovero-button() {
  box-sizing: border-box;
  border-radius: 2px;
  padding: 8px 16px;
  text-decoration: none;

  color: $renovero-white;
  background-color: $renovero-blue;
  cursor: pointer;

  min-height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  &:hover {
    background-color: $renovero-v2-blue-dark;
  }

  &.yellow {
    color: $renovero-blue;
    background-color: $renovero-yellow;

    &:hover {
      background-color: $renovero-v2-secondary-light-yellow;
    }
  }

  &.gray {
    color: $renovero-blue;
    background-color: $renovero-v2-secondary-light-gray;
  }

  &.full-width-mobile {
    display: flex;

    @include from-size-md() {
      display: inline-flex;
    }
  }
}

@mixin renovero-link() {
  cursor: pointer;
  color: $renovero-blue;
  text-decoration: none;
  border-bottom: $renovero-light-blue 0.125em solid;

  &.white {
    color: $renovero-white;
  }

  &:hover {
    border-bottom: $renovero-yellow 0.125em solid;
  }
}

@mixin renovero-input() {
  padding: 10px;
  margin: 0;
  border: 2px solid $renovero-border-color;
  box-sizing: border-box;
  height: 55px;
}

@mixin forced-size($width, $height) {
  max-width: $width;
  min-width: $width;
  min-height: $height;
  max-height: $height;
}

@mixin adjacent-element-shadow($shadow-size: $box-shadow-normal) {
  background: $renovero-white;
  position: relative;
  background: #ffffff;
  border-radius: 2px;
  display: block;

  &::after {
    content: "";
    position: absolute;
    box-shadow: $shadow-size;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin landing-page-form {
  width: 100%;
  box-sizing: border-box;
  padding: $spacing-16;
  background: $renovero-white;
  box-shadow: 0 0 21px 5px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  border-top: 8px solid $renovero-yellow;

  @include from-size-md() {
    position: absolute;
  }

  .title {
    display: flex;
    margin-bottom: $spacing-16;
  }
}

@mixin review-footer {
  margin-top: $spacing-40;
}

@mixin review-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: $spacing-40 0 $spacing-32 0;
  max-height: 45px;
  min-height: 45px;
}

@mixin navigation-back {
  font-weight: $renovero-font-bold;
  text-decoration: none;
  color: $renovero-blue;

  .arrow {
    margin-right: $spacing-12;
  }
}

@mixin company-container {
  display: flex;
  gap: $spacing-16;
  justify-content: space-between;

  .company-logo-wrapper {
    @include forced-size(120px, 80px);

    @include from-size-md() {
      height: 120px;
      width: 180px;
    }

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .company-rating {
    display: flex;
    gap: $spacing-12;
  }
}

@mixin dialog-actions {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: $spacing-24;

  @include from-size-md() {
    align-items: initial;
    flex-direction: row-reverse;
    gap: 0;
    justify-content: space-between;

    .renovero-link {
      align-self: center;
    }
  }

  app-renovero-button {
    width: 100%;

    @include from-size-md() {
      width: auto;
    }
  }
}

@mixin hover-effect() {
  transition: all 0.15s ease-in-out;

  &:hover {
    box-shadow: $box-shadow-medium;
  }
}

@mixin gray-container {
  padding: $spacing-24;
  background-color: $renovero-light-gray;
  display: flex;
  gap: $spacing-24 $spacing-48;
  flex-direction: column;

  @include from-size-md() {
    flex-direction: row;
  }

  .text-container,
  .actions {
    flex: 1;
  }

  .actions {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-24;

    .links {
      row-gap: $spacing-16;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@mixin step-summary() {
  .icon-background {
    display: flex;
    width: 48px;
    height: 48px;
    background-color: $renovero-light-blue;
    border-radius: 50%;
    margin: $spacing-24 $spacing-16 $spacing-16;

    .img {
      margin: auto;
    }
  }

  .main-title {
    display: flex;
  }

  .go-to-step {
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    margin-right: $spacing-24;
    cursor: pointer;
  }

  .headline {
    display: flex;
    font-weight: $renovero-font-bold;
  }

  .step-summary {
    display: flex;
    flex-direction: row;
    box-shadow: $box-shadow-normal;
    margin-bottom: $spacing-16;
    padding-bottom: $spacing-40;

    .card {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .content {
      display: flex;

      .left,
      .right {
        flex: 1;
        flex-direction: column;
      }

      .row {
        display: flex;
      }
    }
  }
}

@mixin print-button {
  background: none;
  border: none;
  padding: 0;
  font-size: 100%;
  font-family: inherit;

  @include renovero-link();
}

@mixin responsive-flex-md {
  display: flex;
  flex-direction: column;

  @include from-size-md() {
    flex-direction: row;
  }
}

@mixin card {
  display: flex;
  flex-direction: column;
  margin: $spacing-16 0;
  padding: 0;
  position: relative;

  @content;

  @include from-size-md() {
    background: #ffffff;
    padding: $spacing-16 $spacing-24 $spacing-16 $spacing-16;
    border-radius: 2px;
    box-shadow: $box-shadow-normal;
  }

  .header {
    display: flex;
    margin-bottom: $spacing-16;

    .title {
      max-width: 242px;
    }

    @include from-size-md() {
      margin-bottom: $spacing-24;
      .title {
        max-width: unset;
      }
    }

    .action {
      display: flex;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
    }
  }
}
