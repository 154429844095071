@import "v2/mixins";

.typography-h1 {
  @include typography-h1();
}

.typography-h2 {
  @include typography-h2();
}

.typography-h3 {
  @include typography-h3();
}

.typography-h4 {
  @include typography-h4();
}

.typography-h5 {
  @include typography-h5();
}

.typography-text-1 {
  @include typography-text-1();

  &--medium {
    @include typography-text-1($font-weight: $renovero-font-medium);
  }

  &--semibold {
    @include typography-text-1($font-weight: $renovero-font-bold);
  }
}

.typography-text-2 {
  @include typography-text-2();

  &--bold-uppercase {
    @include typography-text-2--bold-uppercase();
  }
}

.typography-text-3 {
  @include typography-text-3();

  &--medium {
    @include typography-text-3--medium();
  }

  &--semibold {
    @include typography-text-3--semibold();
  }
}

.typography-text-4 {
  @include typography-text-4();

  &--medium {
    @include typography-text-4--medium();
  }

  &--semibold-uppercase {
    @include typography-text-4--semibold-uppercase();
  }

  &--extrabold-uppercase {
    @include typography-text-4--extrabold-uppercase();
  }
}

.typography-text-5 {
  @include typography-text-5();

  &--medium {
    @include typography-text-5--medium();
  }

  &--bold {
    @include typography-text-5--bold();
  }
}
