@import "abstracts/variables";

/* vertical alignment of content to first vertical visual design line.
NOTE: can contain .mojo-space-between to left/right align content*/
.mojo-flex-container-1 {
  max-width: 960px;
  margin: auto;

  &::before {
    display: flex;
  }
}

/* vertical alignment of content to second vertical visual design line */
.mojo-flex-container-2 {
  max-width: 720px;
  margin: auto;

  &::before {
    display: flex;
  }
}

/* container which takes the full width, usable for distributor background color boxes
NOTE: can contain .mojo-flex-container-1/2 to align content within*/
.mojo-flex-container-full {
  overflow-wrap: anywhere;
}

/* container to left/right align content within .mojo-flex-container-1/2
NOTE: has to have two child div's. First is left content, second is right content*/
.mojo-flex-space-between {
  display: flex;
  justify-content: space-between;
}

/* class to give the extra left/right padding on xs and md
NOTE: use in combination with .mojo-flex-container-1 and .mojo-flex-container-2*/
.mojo-flex-container-padding-below-lg,
.mojo-flex-container-padding-below-md,
.mojo-flex-container-padding-below-md-plus {
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: $mojo-breakpoint-md) {
  .mojo-flex-container-padding-below-lg,
  .mojo-flex-container-padding-below-md-plus {
    padding-left: 24px;
    padding-right: 24px;
  }
  .mojo-flex-container-padding-below-md {
    padding: 0;
  }
}

@media (min-width: $mojo-breakpoint-md-plus) {
  .mojo-flex-container-padding-below-md-plus {
    padding: 0;
  }
}

@media (min-width: $mojo-breakpoint-lg) {
  .mojo-flex-container-padding-below-lg {
    padding: 0;
  }
}

/* breakpoint handling */

.mojo-show-md,
.mojo-show-lg {
  display: none;
}

@media (max-width: $mojo-breakpoint-md-plus) {
  .mojo-hide-xs {
    display: none;
  }
}

@media (min-width: $mojo-breakpoint-md) {
  .mojo-hide-md {
    display: none;
  }
  .mojo-show-md {
    display: initial;
  }
}

@media (min-width: $mojo-breakpoint-md-plus) {
  .mojo-show-lg {
    display: initial;
  }
  .mojo-hide-md-plus {
    display: none;
  }
  .mojo-show-md-plus {
    display: initial;
  }
}
