@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/typography_mixins";

.renovero-datepicker {
  font-family: "Encode Sans", sans-serif;
  @include from-size-md() {
    box-shadow: $box-shadow-normal;
  }
  .mat-calendar-table-header {
    color: $renovero-blue;
    th {
      @include typography-text-small-bold();
    }
    .mat-calendar-abbr {
      text-decoration: none;
    }
  }
  .mat-calendar-table-header-divider {
    display: none;
  }
  .mat-calendar-body-cell {
    &.selected {
      .mat-calendar-body-cell-content {
        background-color: $renovero-yellow !important;
      }
    }
    &::before,
    &::after,
    .mat-calendar-body-cell-preview {
      height: 70%;
    }
    .mat-calendar-body-cell-content {
      border-radius: 5%;
      height: 70%;
      color: $renovero-blue;
    }
  }
  .mat-calendar-body-label {
    visibility: hidden;
  }
  .mat-calendar-body-label[colspan="7"] {
    display: none;
  }
}
