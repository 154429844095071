@import "abstracts/variables";
@import "v2/variables";

.mojo-flex-container-1 {
  max-width: $container-size-v2;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $container-v2-padding-side;
  padding-left: $container-v2-padding-side;
  box-sizing: border-box;
  max-width: $container-v2-max-width-xxl;
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $container-v2-padding-side;
  padding-left: $container-v2-padding-side;
}
