.text-uppercase {
  text-transform: uppercase;
}

.text-small {
  font-size: $font-size-small;
  line-height: 16px;
  font-weight: $renovero-font-medium;
  @include from-size-md() {
    font-size: $font-size-14;
    line-height: 22px;
  }
}

.typography-v2-heading-medium {
  font-size: $font-v2-size-32;
  line-height: 40px;
  font-weight: $renovero-font-bold;
  margin: 0;
}

.typography-v2-heading-large {
  font-size: $font-v2-size-28;
  line-height: 36px;
  font-weight: $renovero-font-bold;
  margin: 0;
  @include from-size-lg() {
    font-size: $font-v2-size-48;
    line-height: 56px;
  }
}

.typography-heading-extra-large {
  font-weight: $renovero-font-bold;
  font-size: $font-v2-size-36;
  line-height: 42px;
  margin: 0;
  @include from-size-lg() {
    font-size: $font-v2-size-64;
    line-height: 68px;
  }
}

.typography-checkbox-label {
  font-size: $font-size-14;
  line-height: 24px;
  font-weight: $renovero-font-medium;
}

.typography-v2-heading-h3 {
  font-size: $font-v2-size-24;
  line-height: 32px;
  font-weight: $renovero-font-bold;
  margin: 0;
  @include from-size-lg() {
    font-size: $font-v2-size-32;
    line-height: 40px;
    font-weight: $renovero-font-bold;
    margin: 0;
  }
}

.typography-v2-heading-h4 {
  font-size: $base-font-size;
  line-height: 24px;
  font-weight: $renovero-font-bold;
  margin: 0;
  @include from-size-lg() {
    font-size: $font-v2-size-24;
    line-height: 32px;
    font-weight: $renovero-font-bold;
    margin: 0;
  }
}
