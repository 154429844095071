@import "abstracts/variables";

html.cdk-global-scrollblock {
  height: initial;
}

.cdk-overlay-backdrop.renovero-backdrop {
  background: rgba($renovero-blue, 0.85);
}

.mdc-dialog__container {
  .mat-mdc-dialog-actions {
    padding: $spacing-24;
  }
}

.mat-mdc-icon-button[mat-dialog-close] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-more-info-contractor-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  position: unset;
}
