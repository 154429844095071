@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/typography_mixins";

.img-vertical-align-bottom {
  vertical-align: bottom;
}

.renovero-link {
  @include renovero-link();
}

.renovero-button {
  @include renovero-button();
}

.renovero-input {
  @include renovero-input();
}

.renovero-badge {
  display: inline-block;
  padding: 4px 12px;
  background: $renovero-light-gray;
  border-radius: 100px;

  @include typography-text-small();
}

.renovero-cta-button {
  @include renovero-button();
  text-align: center;

  &.full-width {
    width: 100%;
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.line-separator {
  background: $renovero-light-gray;
  height: 2px;
  border-radius: 2px;
}

.step-header {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-32;
  @include from-size-md() {
    margin-bottom: $spacing-48;
  }
}

.sidebar-view-header {
  min-height: $spacing-64;
  max-height: $spacing-64;
  display: flex;
  align-items: center;
  margin-top: $spacing-4;
  margin-bottom: $spacing-24;

  @include from-size-md() {
    margin-top: $spacing-32;
    margin-bottom: $spacing-48;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &-from-md {
    white-space: initial;
    overflow: initial;
    text-overflow: initial;

    @include from-size-md() {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.print-only {
  display: none !important;
  @media print {
    display: block !important;
  }
}

.print-hidden {
  @media print {
    display: none !important;
  }
}

.page-heading {
  margin-bottom: $spacing-16;
}

.expand-container {
  cursor: pointer;
  position: absolute;
  top: calc(100% + $spacing-v2-20);
  left: 0;
  border-bottom: 1px solid $renovero-blue;
  height: 22px;
  display: flex;
  align-items: center;

  &__text {
    margin-right: $spacing-v2-8;
    @include typography-text-small-bold();
  }

  &__icon {
    width: 12px !important;
  }

  &--expanded {
    & > .expand-container__icon {
      transform: rotate(180deg);
    }
  }
}
