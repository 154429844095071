@import "./mixins";

html {
  scroll-behavior: smooth;
}

.breadcrumbs-container {
  margin: 16px 0 40px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
