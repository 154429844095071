@import "abstracts/mixins";
@import "abstracts/variables";

@mixin section-normal {
  display: block;
  max-width: $container-size-v2;
  margin: auto;
  padding-left: $spacing-16;
  padding-right: $spacing-16;

  @include from-size-md() {
    padding-left: $spacing-v2-24;
    padding-right: $spacing-v2-24;
  }
}

@mixin section-narrow {
  display: block;
  box-sizing: border-box;
  max-width: $content-max-width;
  margin: auto;
  padding-left: $spacing-16;
  padding-right: $spacing-16;

  @include from-size-md() {
    padding-left: $spacing-196;
    padding-right: $spacing-196;
  }
}
