@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/typography_mixins";

.mat-snack-bar-container {
  &.alert {
    padding: $spacing-8 $spacing-16;
    color: $renovero-white;
    box-shadow: $box-shadow-light;
  }

  &.success {
    background: $mojo-light-green;
  }

  &.warning,
  &.error {
    background: $renovero-light-red;
  }
}
