@import "abstracts/variables";
@import "abstracts/mixins";

.ngx-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: $spacing-4;

  & * {
    margin: 0 !important;
    color: $renovero-blue !important;
  }

  .pagination-previous,
  .pagination-next {
    &::before,
    &::after,
    a::before,
    a::after {
      margin: 0 !important;
      line-height: 10px !important;
    }

    &.disabled::before,
    &.disabled::after,
    a.disabled::before,
    a.disabled::after {
      color: $renovero-gray !important;
    }
  }

  li {
    border-radius: $renovero-border-radius !important;
    padding: $spacing-8 $spacing-16 !important;

    a {
      padding: 0 !important;
      border-radius: $renovero-border-radius !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.current {
      font-weight: $renovero-font-bold !important;
      background-color: $renovero-light-blue !important;
    }
  }
}
