@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/typography_mixins";

.mat-stepper-vertical {
  .mat-step-text-label {
    color: $renovero-blue;
    font-weight: $renovero-font-bold;
  }

  .mat-step-header .mat-step-icon-selected {
    background-color: $renovero-yellow;
  }
}
