// breakpoints
$breakpoint-v2-xs: 0;
$breakpoint-v2-sm: 576px;
$breakpoint-v2-md: 768px;
$breakpoint-v2-lg: 992px;
$breakpoint-v2-xl: 1200px;
$breakpoint-v2-xxl: 1440px;

// containers
$container-v2-max-width-xs: 100%;
$container-v2-max-width-sm: 544px;
$container-v2-max-width-md: 736px;
$container-v2-max-width-lg: 960px;
$container-v2-max-width-xl: 1168px;
$container-v2-max-width-xxl: 1208px;

$container-v2-padding-side: 16px;

// blog
$article-margin-top: 40px;
$article-content-side-padding-xs: 16px;
$article-content-side-padding-md: 24px;
$article-content-gap-lg: 14px;
$article-content-gap-xl: 104px;
$article-cta-column-width: 312px;
$article-breadcrumbs-margin-top: 16px;
$article-testimony-margin-top: 12px;
$article-testimony-content-gap: 12px;
$article-testimony-image-size: 60px;
$article-content-margin-bottom-xs: 50px;
$article-content-margin-bottom-md: 120px;
$article-carousel-gap: 16px;
$article-carousel-height-xs: 222px;
$article-carousel-height-sm: 350px;
$article-carousel-height-md: 522px;
$article-carousel-side-padding: 24px;
$article-carousel-border-radius: 4px;
$article-carousel-navigation-btn-size: 48px;
$article-carousel-navigation-btn-offset: 36px;
$article-carousel-thumbs-height: 417px;
$article-carousel-thumbs-width: 100px;
$article-carousel-thumbs-navigation-btn-offset: 20px;

// forms
$form-v2-max-width: 500px;
