@import "abstracts/variables";
@import "abstracts/mixins";

@mixin scroll-area-height($height) {
  height: calc(100vh - 160px) !important;

  @include from-size-md() {
    height: $height !important;
  }
}

.hidden {
  display: none;
}

.mojo-link {
  text-decoration: none;
  color: $renovero-blue;
}

ndc-dynamic {
  display: none;
}

input {
  font-size: 16px;
}

input[type="text"] {
  @include renovero-input();
}

textarea {
  padding: 10px;
  margin: 0;
  border: 2px solid $renovero-border-color;
  box-sizing: border-box;
  height: 163px;
  font-size: 16px;
}

body {
  overflow-x: hidden;
}

*,
*:focus,
*:hover {
  outline: none;
}

.inner-html {
  a {
    border-bottom: $renovero-light-blue 2px solid;
    color: $renovero-blue;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      border-bottom: $renovero-yellow 2px solid;
    }
  }
}

// Override default 500x500px map size
google-map {
  display: block;
  width: 100%;

  .map-container {
    width: 100% !important;
  }
}

.keyword em {
  color: $renovero-blue;
  background-color: transparent;
  font-weight: bold;
  font-style: normal;
}

.mantine-Popover-dropdown {
  height: 100vh;
  top: 0 !important;
  @include from-size-md() {
    height: initial;
    top: 44.5px !important;
  }

  > div {
    width: 100vw;
    border-radius: 0;
    @include from-size-md() {
      width: 500px;
      border-radius: initial;
    }
  }

  .nc-layout-wrapper {
    padding-top: 45px;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0;

    @include from-size-md() {
      padding-top: initial;
      position: initial;
      top: initial;
      width: 500px;
      height: initial;
      border-radius: 7px;
    }
    .mantine-ScrollArea-root {
      @include scroll-area-height(400px);
    }

    .infinite-scroll-component {
      @include scroll-area-height(400px);
    }
  }
  .mantine-Popover-arrow {
    display: none;
    @include from-size-md() {
      display: initial;
    }
  }
}
