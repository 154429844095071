@import "variables";

@mixin spinner-animation() {
  .renovero-forms-spin {
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@mixin transition($values...) {
  transition: $values;
  -webkit-transition: $values;
  -moz-transition: $values;
  -o-transition: $values;
}

@mixin form-cva-element {
  .form-cva-element {
    display: inline-block;
    width: 100%;
    padding: $renovero-control-padding;
    border: 2px solid $renovero-light-blue;
    background-color: $renovero-white;
    color: $renovero-blue;
    box-sizing: border-box;
    border-radius: 2px;
    @content;

    &.small-field {
      padding: 5px;
    }

    &.form-element {
      border: 2px solid $renovero-border-color;
      padding: $spacing-8 $spacing-16;
    }

    &:disabled,
    &.disabled {
      background-color: $renovero-light-gray;
      color: $renovero-gray;
      cursor: not-allowed;
    }
  }
}

@mixin form-spacing {
  &.none {
    margin: 0;
  }
  &.narrow {
    margin-bottom: $spacing-16;
  }
  &.normal {
    margin-bottom: $spacing-32;
  }
}

@mixin transform-cva($values) {
  transform: $values;
  -webkit-transform: $values;
  -moz-transform: $values;
  -ms-transform: $values;
  -o-transform: $values;
}

@mixin transition-cva($values...) {
  transition: $values;
  -webkit-transition: $values;
  -moz-transition: $values;
  -o-transition: $values;
}

@mixin form-cva-error-style {
  @include transition-cva(background-color 0.1s linear);

  $border-left-width: 5px;
  padding-left: $renovero-control-padding - $border-left-width + 1;
  border-left: $border-left-width solid $error-color;
  border-bottom-color: $error-color !important;
  color: $error-color;

  &[value=""],
  &:empty {
    @include input-placeholder {
      color: $error-background-color;
    }
  }
}

@mixin form-cva-error {
  .renovero-component-invalid {
    &.form-cva-element {
      &.renovero-component-validated {
        @include form-cva-error-style;
      }
    }
  }
}

@mixin form-error {
  .renovero-invalid {
    &.form-element {
      &.renovero-touched {
        @include form-error-style;
      }
    }
  }
}

@mixin form-error-style {
  @include transition(background-color 0.1s linear);

  $border-left-width: 5px;
  padding-left: $renovero-control-padding - $border-left-width + 1;
  border-left: $border-left-width solid $error-color;
  border-bottom-color: $error-color !important;
  color: $error-color;

  &[value=""],
  &:empty {
    @include input-placeholder {
      color: $error-background-color;
    }
  }
}

@mixin input-placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  //firefox 19+
  &::-moz-placeholder {
    @content;
  }

  //ie
  &:-ms-input-placeholder {
    @content;
  }

  //firefox 19-
  &:-moz-placeholder {
    @content;
  }

  //custom class for non html5 cases
  & + .placeholder {
    position: absolute;
    top: 0;
    height: 100%;
    padding: 12px;
    padding-bottom: 24px;
    border: 1px solid transparent;
    font-weight: 400;
    white-space: pre-line;
    overflow: hidden;
    visibility: hidden;
    z-index: 0;
    box-sizing: border-box;
    user-select: none;
    -moz-user-select: -moz-none;
    @content;
  }

  &.show-placeholder {
    z-index: 1;

    & + .placeholder {
      visibility: visible;
    }
  }
}

@mixin radio-buttons-with-spacing($spacing) {
  renovero-form-radio-button-cva {
    padding-right: $spacing;
    padding-bottom: $spacing;

    &:last-child {
      padding-right: 0;
      padding-bottom: 0;
    }
  }
}
