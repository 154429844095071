@import "abstracts/variables";

html {
  height: 100%;
}

body {
  font-family: "Encode Sans", sans-serif;
  font-size: 16px;
  font-style: normal;

  color: $renovero-blue;

  line-height: 1;
  margin: 0px;

  height: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    margin: 0;
    padding: 0;
  }
}

h2 {
  margin: 0;
}

label[for] {
  cursor: pointer;
}
